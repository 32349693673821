import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dac349bc = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _00387077 = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _19c78912 = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _e3b52c60 = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _e1c56414 = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _6d8d23de = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _72343e48 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _a3399ed6 = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _696e8b1c = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _bd1a1a98 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _9ae3a9b8 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _52ab21f5 = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _55a63169 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _cd5954c0 = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _111b0fc5 = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _a569faf4 = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _ca2c1876 = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _dc807a0c = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _8cfac620 = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _3b1313fb = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _4972f620 = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _4cf6bb5e = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _04e2055d = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _07326b86 = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _c28acfb2 = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _9f00893c = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _5acb829f = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _52ec2cfb = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _510f44aa = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _c2d45c48 = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _2edaef8c = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _140ac248 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _7db13414 = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _8058f8c4 = () => interopDefault(import('../pages/unit/category/index.vue' /* webpackChunkName: "pages/unit/category/index" */))
const _6833103c = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _3d951cbb = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _7a127de1 = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _5a883a60 = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _60acc030 = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _3f2cb7a0 = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _34cfc264 = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _22f4a134 = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _1ec4da84 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _dac349bc,
    name: "account"
  }, {
    path: "/action",
    component: _00387077,
    name: "action"
  }, {
    path: "/archive",
    component: _19c78912,
    name: "archive"
  }, {
    path: "/audit",
    component: _e3b52c60,
    name: "audit"
  }, {
    path: "/deviation",
    component: _e1c56414,
    name: "deviation"
  }, {
    path: "/login",
    component: _6d8d23de,
    name: "login"
  }, {
    path: "/logout",
    component: _72343e48,
    name: "logout"
  }, {
    path: "/offline",
    component: _a3399ed6,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _696e8b1c,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _bd1a1a98,
    name: "organization"
  }, {
    path: "/register",
    component: _9ae3a9b8,
    name: "register"
  }, {
    path: "/report",
    component: _52ab21f5,
    name: "report"
  }, {
    path: "/search",
    component: _55a63169,
    name: "search"
  }, {
    path: "/styles",
    component: _cd5954c0,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _111b0fc5,
    name: "suggestion"
  }, {
    path: "/task",
    component: _a569faf4,
    name: "task"
  }, {
    path: "/unit",
    component: _ca2c1876,
    name: "unit"
  }, {
    path: "/verify",
    component: _dc807a0c,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _8cfac620,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _3b1313fb,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _4972f620,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _4cf6bb5e,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _04e2055d,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _07326b86,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _c28acfb2,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _9f00893c,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _5acb829f,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _52ec2cfb,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _510f44aa,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _c2d45c48,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _2edaef8c,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _140ac248,
    name: "password-reset"
  }, {
    path: "/task/group",
    component: _7db13414,
    name: "task-group"
  }, {
    path: "/unit/category",
    component: _8058f8c4,
    name: "unit-category"
  }, {
    path: "/action/:id",
    component: _6833103c,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _3d951cbb,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _7a127de1,
    name: "deviation-id"
  }, {
    path: "/report/:id",
    component: _5a883a60,
    name: "report-id"
  }, {
    path: "/suggestion/:id",
    component: _60acc030,
    name: "suggestion-id"
  }, {
    path: "/unit/:id",
    component: _3f2cb7a0,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _34cfc264,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _22f4a134,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _1ec4da84,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
