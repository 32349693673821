
export default {
  name: 'NowButton',
  props: {
    type: {
      type: String,
      validator: val => [
        'button',
        'reset',
        'submit'
      ].includes(val),
      default: 'button'
    },
    layout: {
      type: String,
      validator: val => [
        'default',
        'auto',
        'minimal',
        'minimal-bordered',
        'minimal-rounded',
        'icon',
        'icon-button',
        'transparent-bordered'
      ].includes(val),
      default: 'default'
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    form: {
      type: String,
      default: null
    },
    formTarget: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    borderRadius: {
      type: String,
      default: 'rounded-lg'
    },
    backgroundColor: {
      type: String,
      default: 'bg-inherit'
    },
    textColor: {
      type: String,
      default: 'text-inherit'
    }
  },
  computed: {
    background () {
      // If default button then set default color to brand beige
      if ((this.layout === 'default' || this.layout === 'auto') && this.backgroundColor === 'bg-inherit') {
        return 'bg-beige-800'
      } else {
        return this.backgroundColor
      }
    }
  }
}
