
import dialogTypes from '@/constants/dialogTypes'
export default {
  name: 'NowDialog',
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    note: {
      type: String,
      default: null
    },
    illustration: {
      type: String,
      default: null
    },
    type: {
      type: String,
      validator: val => ['dialog', 'confirm', 'alert', 'prompt'].includes(val),
      default: 'dialog'
    },
    loading: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: null
    },
    confirmText: {
      type: String,
      default: null
    },
    validationText: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      form: {
        validationResponse: null
      },
      dialogTypes
    }
  },
  computed: {
    showIcon () {
      return this.type === dialogTypes.CONFIRM || this.type === dialogTypes.DIALOG || this.type === dialogTypes.ALERT
    },
    isValid () {
      if (this.validationText) {
        return this.validationText === this.form.validationResponse
      } else {
        return true
      }
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyEventHandler)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyEventHandler)
  },
  methods: {
    keyEventHandler (event) {
      if (event.key === 'Escape') {
        this.$emit('close', event)
      }
    },
    confirm () {
      this.$emit('confirm')
    },
    close () {
      this.$emit('close')
    }
  }
}
