import { render, staticRenderFns } from "./default.vue?vue&type=template&id=7449f09a&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=7449f09a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7449f09a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowEmailUnverified: require('/home/forge/dev.isoudvikling.com/releases/20240926142816/components/NowEmailUnverified.vue').default,NowSessionModal: require('/home/forge/dev.isoudvikling.com/releases/20240926142816/components/NowSessionModal.vue').default,NowSidebar: require('/home/forge/dev.isoudvikling.com/releases/20240926142816/components/NowSidebar.vue').default,NowNav: require('/home/forge/dev.isoudvikling.com/releases/20240926142816/components/NowNav.vue').default,NowPortals: require('/home/forge/dev.isoudvikling.com/releases/20240926142816/components/NowPortals.vue').default,NowEnvironmentBar: require('/home/forge/dev.isoudvikling.com/releases/20240926142816/components/NowEnvironmentBar.vue').default})
