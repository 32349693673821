import * as C from './constants'

export default {

  /**
   * @param commit
   * @param $config
   * @param payload
   * @returns {Promise<void>}
   */
  toggleVerifyEmailModal ({ commit }, payload) {
    commit(C.APP_TOGGLE_VERIFY_EMAIL_MODAL, payload)
  },

  /**
   * @param commit
   * @param payload
   */
  toggleSidebar ({ commit }, payload) {
    commit(C.APP_TOGGLE_SIDEBAR, payload)
  },

  /**
   * @param commit
   * @param payload
   */
  collapseSidebar ({ commit }, payload) {
    commit(C.APP_COLLAPSE_SIDEBAR, payload)
  },

  /**
   * @param state
   * @param payload
   */
  toggleSessionExpired ({ commit }, payload) {
    commit(C.APP_TOGGLE_SESSION_EXPIRED, payload)
  },

  /**
   * @param commit
   */
  resetAppStore ({ commit }) {
    commit(C.APP_RESET_STORE)
  },

  /**
   * @param dispatch
   */
  resetFullStore ({ dispatch }) {
    dispatch('actionPlan/resetActionPlanStore', null, { root: true })
    dispatch('actionPlanTask/resetActionPlanTaskStore', null, { root: true })
    dispatch('approval/resetApprovalStore', null, { root: true })
    dispatch('approvalComment/resetApprovalCommentStore', null, { root: true })
    dispatch('archive/resetArchiveStore', null, { root: true })
    dispatch('archiveFile/resetArchiveFileStore', null, { root: true })
    dispatch('archiveFileBookmark/resetArchiveFileBookmarkStore', null, { root: true })
    dispatch('audit/resetAuditStore', null, { root: true })
    dispatch('auditor/resetAuditorStore', null, { root: true })
    dispatch('businessType/resetBusinessTypeStore', null, { root: true })
    dispatch('company/resetCompanyStore', null, { root: true })
    dispatch('companyUnit/resetCompanyUnitStore', null, { root: true })
    dispatch('conversation/resetConversationStore', null, { root: true })
    dispatch('cvr/resetCvrStore', null, { root: true })
    dispatch('deviation/resetDeviationTypeStore', null, { root: true })
    dispatch('deviationFile/resetDeviationFileStore', null, { root: true })
    dispatch('file/resetFileStore', null, { root: true })
    dispatch('google/resetGoogleStore', null, { root: true })
    dispatch('jobTitle/resetJobTitleStore', null, { root: true })
    dispatch('log/resetLogStore', null, { root: true })
    dispatch('notification/resetNotificationsStore', null, { root: true })
    dispatch('organization/resetOrganizationStore', null, { root: true })
    dispatch('organizationBilling/resetOrganizationBillingStore', null, { root: true })
    dispatch('organizationGroup/resetOrganizationGroupStore', null, { root: true })
    dispatch('organizationReportType/resetOrganizationReportTypeStore', null, { root: true })
    dispatch('organizationSubscription/resetOrganizationSubscriptionStore', null, { root: true })
    dispatch('permission/resetPermissionStore', null, { root: true })
    dispatch('procedureAppendix/resetProcedureAppendixStore', null, { root: true })
    dispatch('procedureTemplate/resetProcedureTemplateStore', null, { root: true })
    dispatch('report/resetReportStore', null, { root: true })
    dispatch('reportProcedure/resetReportProcedureStore', null, { root: true })
    dispatch('reportProcedureAppendix/resetReportProcedureAppendixStore', null, { root: true })
    dispatch('reportProcedureAppendixSection/resetReportProcedureAppendixSectionStore', null, { root: true })
    dispatch('reportProcedureContent/resetReportProcedureContentStore', null, { root: true })
    dispatch('reportProcedureContentSection/resetReportProcedureContentSectionStore', null, { root: true })
    dispatch('reportSection/resetReportSectionStore', null, { root: true })
    dispatch('reportTemplate/resetReportTemplateStore', null, { root: true })
    dispatch('reportType/resetReportTypeStore', null, { root: true })
    dispatch('role/resetRoleStore', null, { root: true })
    dispatch('sectionType/resetSectionTypeStore', null, { root: true })
    dispatch('snippet/resetSnippetStore', null, { root: true })
    dispatch('statistics/resetStatisticsStore', null, { root: true })
    dispatch('subscription/resetSubscriptionStore', null, { root: true })
    dispatch('suggestion/resetSuggestionStore', null, { root: true })
    dispatch('suggestionFile/resetSuggestionFileStore', null, { root: true })
    dispatch('table/resetTableStore', null, { root: true })
    dispatch('task/resetTaskStore', null, { root: true })
    dispatch('taskComment/resetTaskCommentStore', null, { root: true })
    dispatch('taskFile/resetTaskStore', null, { root: true })
    dispatch('unit/resetUnitStore', null, { root: true })
    dispatch('unitCategory/resetUnitCategoryStore', null, { root: true })
    dispatch('unitFile/resetUnitFileStore', null, { root: true })
    dispatch('userModelRead/resetUserModelReadStore', null, { root: true })
    dispatch('subscriptionOrganization/resetSubscriptionOrganizationStore', null, { root: true })
    dispatch('reportCategory/resetReportCategoryStore', null, { root: true })
    dispatch('twoFactor/resetTwoFactorStore', null, { root: true })
  }
}
