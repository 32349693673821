import moment from 'moment'

import 'moment/locale/da'
import 'moment/locale/de'
import 'moment/locale/sv'
import 'moment/locale/nb'
import 'moment/locale/en-gb'

moment.locale('da')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
